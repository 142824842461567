// https://dev.to/christopherkade/adding-internationalization-to-your-nuxt-js-applications-39jd

import Vue from "vue";
import VueI18n from "vue-i18n";

// Tell Vue to use our plugin
Vue.use(VueI18n);
export default (function (_ref) {
  var app = _ref.app;
  // Set the i18n instance on app
  // This way we can use it globally in our components through this.$i18n
  app.i18n = new VueI18n({
    // Set the initial locale
    locale: "us",
    // Set the fallback locale in case the current locale can't be found
    fallbackLocale: "us",
    // Associate each locale to a content file    
    messages: {
      us: require("src/i18n/us.json"),
      es: require("src/i18n/es.json"),
      cn: require("src/i18n/cn.json")
    }
    //silentTranslationWarn: true
  });
});