import "core-js/modules/es6.function.name.js";
export var state = function state() {
  return {
    script: {},
    lastBlock: {
      height: 0,
      time: 0
    },
    db: {},
    fiat: -1,
    bars: [],
    ticks: []
  };
};
var buttons = {
  yes: {
    text: "Yes",
    color: "green",
    icon: "ok.svg"
  },
  no: {
    text: "No",
    color: "red",
    icon: "close_red.svg"
  },
  create: {
    text: "Create",
    color: "green",
    icon: "ok.svg"
  },
  send: {
    text: "Send",
    color: "green",
    icon: "send2.svg"
  },
  cancel: {
    text: "Cancel",
    color: "red",
    icon: "close_red.svg"
  },
  set: {
    text: "Set",
    color: "green",
    icon: "ok.svg"
  },
  close: {
    text: "Close",
    color: "red",
    icon: "close_red.svg"
  }
};
export var getters = {
  yes_no_buttons: function yes_no_buttons(state) {
    return [buttons.yes, buttons.no];
  },
  create_cancel_buttons: function create_cancel_buttons(state) {
    return [buttons.create, buttons.cancel];
  },
  send_cancel_buttons: function send_cancel_buttons(state) {
    return [buttons.send, buttons.cancel];
  },
  close_button: function close_button(state) {
    return [buttons.close];
  },
  set_button: function set_button(state) {
    return [buttons.set];
  },
  script: function script(state) {
    return state.script;
  },
  // copy / paste scripts
  lastBlock: function lastBlock(state) {
    return state.lastBlock;
  },
  db_all: function db_all(state) {
    return state.db;
  },
  donation_address: function donation_address(state) {
    return 'bc1q2l7j4l43hcy3pfj32fcnvg0kqtezgaz2v7hrw7';
  },
  db: function db(state) {
    return function (name) {
      if (!state.db) return false;
      return state.db[name];
    };
  },
  fiat: function fiat(state) {
    return state.fiat;
  },
  bars: function bars(state) {
    return state.bars;
  },
  ticks: function ticks(state) {
    return state.ticks;
  }
};
export var mutations = {
  script: function script(state, _script) {
    state.script = _script;
  },
  lastBlock: function lastBlock(state, block) {
    state.lastBlock = block;
  },
  db: function db(state, _ref) {
    var name = _ref.name,
      value = _ref.value;
    state.db[name] = value;
  },
  fiat: function fiat(state, _fiat) {
    return state.fiat = _fiat;
  },
  bars: function bars(state, _bars) {
    return state.bars = _bars;
  },
  rate: function rate(state, _ref2) {
    var _rate = _ref2.rate;
    var lastBar = state.bars[state.bars.length - 1];
    if (!!lastBar) lastBar.close = _rate;
    state.ticks.unshift({
      x: new Date(),
      y: _rate
    });
  }
};
export var actions = {// context <-> { state, getters, commit, dispatch }
};
export default {
  namespaced: true,
  getters: getters,
  mutations: mutations,
  state: state
};