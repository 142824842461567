var tempLayout = function tempLayout() {
  return import('src/layouts/TempLayout.vue');
};
var bitpassLayout = function bitpassLayout() {
  return import('src/layouts/BitpassLayout.vue');
};
var salesLayout = function salesLayout() {
  return import('src/layouts/SalesLayout.vue');
};
// <router-link :to="{ name: 'user', params: { userId: 123 }}">User</router-link>

/// MAIN .------------------------------------------------------------------------------------
var routes = [
// router.push({ name: 'user', params: { userId: 123 }})
{
  path: '/',
  component: bitpassLayout,
  children: [{
    path: '',
    name: 'home',
    component: function component() {
      return import('src/pages/home.vue');
    }
  }]
},
// bitpass
{
  path: '/temp',
  component: tempLayout,
  children: [{
    path: '',
    name: 'temp',
    component: function component() {
      return import('pages/temp.vue');
    }
  }]
}, {
  path: '/board',
  component: bitpassLayout,
  children: [{
    path: '',
    name: 'board',
    component: function component() {
      return import('pages/board.vue');
    }
  }, {
    path: 'client/:id',
    name: 'board-client',
    component: function component() {
      return import('pages/board.vue');
    }
  }]
}, {
  path: '/sales',
  component: salesLayout,
  children: [{
    path: 'backoffice',
    name: 'sales-backoffice',
    component: function component() {
      return import('pages/sales/backoffice.vue');
    }
  }, {
    path: ':id',
    name: 'sales-id',
    component: function component() {
      return import('pages/sales/index.vue');
    }
  }, {
    path: '',
    name: 'sales',
    component: function component() {
      return import('pages/sales/index.vue');
    }
  }]
}, {
  path: '/resetpassword',
  component: bitpassLayout,
  children: [{
    path: ':id',
    name: 'resetpassword-id',
    component: function component() {
      return import('pages/resetpassword.vue');
    }
  }]
}, {
  path: '/aftermessage',
  component: bitpassLayout,
  children: [{
    path: ':id',
    name: 'aftermessage-id',
    component: function component() {
      return import('pages/aftermessage.vue');
    }
  }]
}, {
  path: '/help',
  component: bitpassLayout,
  children: [{
    path: 'demo',
    name: 'help-demo',
    component: function component() {
      return import('pages/help/demo.vue');
    }
  }, {
    path: 'settings',
    name: 'help-settings',
    component: function component() {
      return import('pages/help/settings.vue');
    }
  }, {
    path: 'inheritances',
    name: 'help-inheritances',
    component: function component() {
      return import('pages/help/inheritance.vue');
    }
  }]
},
//  ADMIN -------------------
{
  path: '/admin',
  component: bitpassLayout,
  children: [{
    path: '',
    name: 'admin',
    component: function component() {
      return import('pages/admin/index.vue');
    }
  }, {
    path: 'thirdparty',
    name: 'admin-thirdparty',
    component: function component() {
      return import('pages/admin/thirdparty.vue');
    }
  }, {
    path: 'messages',
    name: 'admin-messages',
    component: function component() {
      return import('pages/admin/messages.vue');
    }
  }, {
    path: 'contacts',
    name: 'admin-contacts',
    component: function component() {
      return import('pages/admin/contacts.vue');
    }
  }, {
    path: 'stats',
    name: 'admin-stats',
    component: function component() {
      return import('pages/admin/stats.vue');
    }
  }, {
    path: 'referrals',
    name: 'admin-referrals',
    component: function component() {
      return import('pages/admin/referrals.vue');
    }
  }, {
    path: 'aftermessage',
    name: 'admin-aftermessage',
    component: function component() {
      return import('pages/admin/aftermessage.vue');
    }
  }, {
    path: 'mongo',
    name: 'admin-mongo',
    component: function component() {
      return import('pages/admin/mongo.vue');
    }
  }]
}, {
  path: '/notfound',
  component: bitpassLayout,
  children: [{
    path: '',
    name: 'notfound',
    component: function component() {
      return import('pages/NotFound.vue');
    }
  }]
}];
routes.push({
  path: '*',
  component: function component() {
    return import('pages/NotFound.vue');
  }
});
export default routes;